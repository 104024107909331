import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {SystemActionCategory, SystemPageType } from "../../../../Model/SystemModels";
import { ActionArea } from "../../../Common/Template/Action/ActionArea";
import { ActionEntry } from "../../../Common/Template/Action/ActionEntry";
import { ListHeaderEntry } from "../../../Common/Template/List/ListHeaderEntry";
import PageContainer, { PageContainerBase } from "../../../Common/Template/PageContainer";
import { ContainerType, PageDefinitionContainer } from "../../../Common/Template/PageDefinitionContainer";
import { AdminPartnerCard } from "../AdminPartnerCard/AdminPartnerCard";
import { MessageType, ToastService } from "../../../../Core/Toast/ToastService";

export const AdminPartnerList : React.FC = () => {
    const navigate = useNavigate();
    const cardRef = useRef<PageContainerBase>(null);
    
    const getReferralLink = (hashKey: string) => {
        if (hashKey) {
            let baseUrl: string = process.env.REACT_APP_CUSTOMER_PORTAL_BASE_ADDRESS!;
            navigator.clipboard.writeText(baseUrl+"/register?referral="+hashKey)
            .then(() => {
                ToastService.showMessage(MessageType.Success, "Link copiato negli appunti");
            })
            .catch((jqXHR) => {
                console.log(jqXHR);
                ToastService.showMessage(MessageType.Error, "Impossibile copiare il link negli appunti");
            })
        }
        else
            ToastService.showMessage(MessageType.Error, "Chiave referral non presente, impossibile generare il link negli appunti");
    }

    return (
        <>
            <PageContainer endpoint="/odata/partner" pageType={SystemPageType.List} title="Lista partner" backAllowed insertAllowed updateAllowed cardRef={cardRef}>
                <PageDefinitionContainer type={ContainerType.Action}>
                    <ActionArea category={SystemActionCategory.Navigation}>
                        <ActionEntry iconName="Settings" label="Invoice Setup" name="OpenPartnerInvoiceSetup" onClick={()=>{
                            navigate("/app/admin/administrative/partnerInvoiceSetupList")
                        }}>
                        </ActionEntry>
                        <ActionEntry iconName="Money" label="Budget" name="OpenPartnerBudget" onClick={()=>{
                            navigate("/app/admin/administrative/partnerBudget")
                        }}>
                        </ActionEntry>
                    </ActionArea>
                    <ActionArea category={SystemActionCategory.Process}>
                        <ActionEntry iconName="PageLink" label="Genera link referral" name="GenerateLink" runOnRec onClick={(req:any) => {
                            getReferralLink(req.hashKey);
                        }}>
                        </ActionEntry>
                    </ActionArea>
                </PageDefinitionContainer>                    
                <PageDefinitionContainer type={ContainerType.PageStructure}>
                    <ListHeaderEntry name="Id" fieldName="id" minWidth={100} maxWidth={200} />
                    <ListHeaderEntry name="Partner business contract" fieldName="partnerBusinessContractCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Nome" fieldName="name" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Indirizzo" fieldName="streetAddress" minWidth={250} maxWidth={300} />
                    <ListHeaderEntry name="Città" fieldName="city" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="CAP" fieldName="zipCode" minWidth={70} maxWidth={100} />
                    <ListHeaderEntry name="Provincia" fieldName="county" minWidth={70} maxWidth={100} />
                    <ListHeaderEntry name="Stato" fieldName="country" minWidth={70} maxWidth={100} />
                    <ListHeaderEntry name="Telefono" fieldName="phoneNo" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="PEC" fieldName="pecEmail" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Partita IVA" fieldName="vat" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="CRM profiling" fieldName="crmEosProfiling" minWidth={200} maxWidth={300} />
                    <ListHeaderEntry name="Id shop" fieldName="shopId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Url relazione" fieldName="relationshipUrl" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Percentuale commissione" fieldName="commissionPercentage" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id contratto principale" fieldName="mainContractId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id contratto principale Abletech" fieldName="abletechContractId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id contratto principale Abletech doppia firma" fieldName="abletechDoubleSignatureContractId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id contratto ditta secondaria Abletech" fieldName="abletechSlaveAooContractId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id contratto ditta secondaria Abletech doppia firma" fieldName="abletechDoubleSignatureSlaveAooContractId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id cancellazione contratto abletech" fieldName="abletechCancellationContractId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Url shop stripe" fieldName="stripeShopUrl" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Key stripe" fieldName="stripeKey" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Chiave pubblica stripe" fieldName="stripePublicKey" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Token webhook stripe" fieldName="stripeWebhookToken" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Id iva stripe" fieldName="stripeIvaId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Dns" fieldName="dns" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="id CRM partner" fieldName="crmPartnerId" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Codice promo nuovo lead" fieldName="newLeadPromoCode" minWidth={150} maxWidth={250} />
                    <ListHeaderEntry name="Chiave Referral" fieldName="hashKey" minWidth={150} maxWidth={250} />
                </PageDefinitionContainer>
            </PageContainer>
            <AdminPartnerCard ref={cardRef} />
        </>
    );
}